import * as React from "react"
import { Link } from "gatsby"
import { FC } from "react"
import * as styles from '../css/heading1.module.css'

type Heading1Props = {
    text: string
} 

export const Heading1: FC<Heading1Props>= ({ text}) => {
  return (
    <div className={styles.contianer}>
      <div className={styles.text}>
      { text }
      </div>
    </div>
  )
}

