import { PageProps } from "gatsby";
import React, { FC } from "react";
import Layout from "../components/layout";
import { PageTitle } from "../components/page-title";
import { SearchBar } from "../components/search-bar";
import { Heading1 } from "../components/heading-1";
import Seo from "../components/seo";
import * as styles from '../css/contact.module.css'
import { ContainerCenter } from "../components/container-center";

const SearchPage: FC<PageProps>=({ location })=>{
  const siteTitle='test';
  return (
    <Layout location={location} title={siteTitle}>
      <Seo title={siteTitle} lang="ja" description="TODO: KIFU PLANET DESCRIPTION" />
      <PageTitle pageTitle="CONTACT" discription="お問い合わせ" />

      <ContainerCenter>
        <div className={styles.containerLeft}>
            <Heading1 text="PROFILE"/>
          </div>
          <div className={styles.containerRight}>
            <div className={styles.containerSubtitle}>
              お問い合わせはこちらから
            </div>
            <div className={styles.containerText}>
              人のやりたいを効率よく実現できるようにするためのプラットフォームを作るため、システム開発を行う集団です。<br></br>
              私たちはシステム開発を行う上で、開発のスピードと品質の追求を常に行います。<br></br>
              スピードを向上させるために、世の中にあるフレームワークの活用やコンテンツ生成の自動化を原則とし、作業者に依存しない開発運用の仕組みを整備しています。<br></br>
              また、品質を向上させるために、利用者目線に立ったサービスの構築を徹底しています。<br></br>
            
            <div className={styles.containerList}>
              <ul>
                <li>
                  団体名: MONO BUILDERS
                </li>
                <li>
                  設立年月日: 2022/5/22
                </li>
                <li>
                  連絡先: monobuilders.work@google.com
                </li>
              </ul>
            </div>
            </div>
          </div>
      </ContainerCenter>
    </Layout>
  )
}

export default SearchPage;