import * as React from "react"
import { Link } from "gatsby"
import { FC } from "react"
import * as styles from '../css/page-title.module.css'

type PageTitleProps = {
    pageTitle: string,
    discription: string
} 

export const PageTitle: FC<PageTitleProps>= ({ pageTitle, discription}) => {
  return (
    <div className={styles.contianer}>
        <h1 className={styles.pageTitle}>
            {pageTitle}
        </h1>
        <div className={styles.discription}>
            {discription}
        </div>
    </div>
  )
}